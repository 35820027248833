var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"toast show",style:('background:' + _vm.status_custom_color.replace('0.25', '0.1')),attrs:{"id":'taskID_' + _vm.task.id}},[_c('div',{staticClass:"toast-body"},[_c('task-item-main-block',{attrs:{"task":_vm.task}}),(_vm.task.client)?_c('task-item-client-block',{attrs:{"task_client":_vm.task.client}}):_vm._e(),(_vm.task.car)?_c('task-item-car-block',{attrs:{"task_car":_vm.task.car}}):_vm._e(),_c('task-item-term-block',{attrs:{"task_term_date":_vm.task.term_date,"task_term_time":_vm.task.term_time,"task_term_label":_vm.task.term_label,"task_updated_date":_vm.task.updated_date,"task_type_id":_vm.task.type_id,"task_type_state_id":_vm.task.type_state_id}}),_c('task-item-responsible-block',{attrs:{"task_responsible":_vm.task.responsible}}),_c('task-item-creator-block',{attrs:{"task_creator":(_vm.task.creator ? _vm.task.creator : _vm.task.client),"task_updated_date":_vm.task.updated_date}}),(_vm.task.last_comment)?_c('task-item-comment-block',{attrs:{"task_last_comment":_vm.task.last_comment,"with_delimiter":true}}):_vm._e(),(Object.keys(_vm.task.details).length)?_c('task-item-details-block',{attrs:{"task_details":_vm.task.details,"task_car_price":_vm.task.car ? _vm.task.car.price : 0,"with_delimiter":false}}):_vm._e(),_c('div',{staticClass:"mt-2",staticStyle:{"display":"flow-root"}},[(_vm.task.type_state_id === 301 || !_vm.task.car_id)?_c('router-link',{staticClass:"btn btn-success font-medium pull-right",attrs:{"to":{ name: 'car.create', params: {
                     currentCarData: _vm.task.details,
                     currentClientItem: _vm.task.client,
                     sellerTaskID: _vm.task.id,
                   }},"title":"Добавить авто","type":"button"}},[_vm._v(" +"),_c('i',{staticClass:"fa fa-car margin-l-5"})]):_vm._e()],1)],1),_c('div',{staticClass:"toast-header",style:('background:' + _vm.status_custom_color.replace('0.25', '0.1'))},[([1, 7, 8, 9].includes(_vm.$store.getters['auth/authUser'].role.id) ||
                _vm.$store.getters['auth/authUser'].id === _vm.task.responsible_id)?_c('button',{staticClass:"btn btn-primary",attrs:{"title":"Редактировать","type":"button"},on:{"click":() => {
              _vm.EventBus.$emit('open-edit-task-modal', {
                task: _vm.task,
              });
            }}},[_c('i',{staticClass:"fa fa-edit"})]):_vm._e(),_c('router-link',{staticClass:"btn btn-success margin-l-5",attrs:{"to":{ name: 'task.details', params: { taskId: _vm.task.id, currentTab: 'История' }},"title":"Детали","type":"button"}},[_c('i',{staticClass:"fa fa-info-circle"})]),([1, 7, 8, 9].includes(_vm.$store.getters['auth/authUser'].role.id))?_c('button',{staticClass:"btn btn-warning margin-l-5",attrs:{"title":"В архив","type":"button"},on:{"click":function($event){return _vm.removeTask(_vm.task.id)}}},[_c('i',{staticClass:"fa fa-trash"})]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }